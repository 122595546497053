body {
    background-color: #F4F4F4;
    font-family: Arial, Helvetica, sans-serif;
    color: #666666;
}

a, a:visited, a:active {
    color: #e2414c;
}
a:hover {
    color: #ff5e6a;
}

.input-container {

}

@media screen and (min-width: 600px) {
    .input-container {
        display: flex;
        justify-content: space-between;
    }
}

select, input[type=text], input[type=email], input[type=number], textarea {
    background: white;
    padding: 15px 25px;
    border: 1px solid #ececec;
    border-radius: 50px;
    font-size: 15px;
    -webkit-appearance: none;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 8px;
}

@media screen and (min-width: 600px) {
    select, input[type=text], input[type=email], input[type=number], textarea {
        /*width: 95%;*/
    }
}

.has-error {
    border: 1px solid #e2414c !important;
    background: #ffaeb3 !important;
}

h1 {
    color: #e2414c;
}
h2 {
    margin-bottom: 15px;
}
h3 {
    color: #6e4747;
}

.hidden {
    display: none;
}

.container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
}

.button-container {
    text-align: center;
    margin-top: 50px;
}

.submitbutton {
    /*width: 100%;*/
    color: #fff;
    background: #e2414c;
    padding: 15px 25px;
    border: none;
    margin-top: 30px;
    border-radius: 50px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 700;
}
.addbutton {
    background: #00aa00;
    margin-bottom: 25px;
    /*width: 250px;*/
}
#removebutton {
    width: 200px;
}

.disabled {
    color: #777;
    background: #cccccc;
    cursor: not-allowed;
}

.alert {
    padding: 5px 10px;
}
.alert-danger {
    border: 1px solid #e2414c;
    background: #ff4d5d;
    color: #ffffff;
}

.steps {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 50px;
}
.step {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.step span {
    background: #E30613;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    margin-bottom: 5px;
    color: white;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    z-index: 5;
}

@media screen and (min-width: 600px) {
    .step span {
        height: 70px;
        width: 70px;
        border-radius: 70px;
    }
}

.step-completed {
    font-weight: bold;
    color: #3cb43c;
}

.step-completed span {
    background: #3cb43c;
}
.step-spacer {
    flex-grow: 1;
    /*padding-top: 16px;*/
    /*padding-left: 10px;*/
    /*padding-right: 10px;*/
    position: relative;
}
.step-spacer-inner {
    border: 3px solid #E30613;
    height: 0;
    position: absolute;
    width: 165%;
    left: -20px;
    top: 25px;
}

@media screen and (min-width: 600px) {
    .step-spacer-inner {
        width: 110%;
        left: -10px;
        top: 33px;
    }
}


.step-spacer-completed {
    border: 3px solid #3cb43c;
}


@media screen and (min-width: 600px) {
    .middle {
        margin-left: 20px;
        margin-right: 20px;
    }
    .sendungen-container {
        display: flex;
        justify-content: space-between;
    }

    .sendungen-container .left {
        flex: 0 55%;
        max-width: 55%;
    }
    .sendungen-container .right {
        flex: 0 35%;
        max-width: 35%;
    }
}

.summary-sendungen {
    margin-top: 15px;
    padding-bottom: 15px;
    border-top: 1px dashed #666666;
    border-bottom: 1px dashed #666666;
}

.price {
    margin-top: 30px;
    width: 100%;
    text-align: right;
}
#price {
    font-size: 20px;
    font-weight: bold;
}

#summary {
    border-radius: 10px;
    /*width: 250px;*/
    /*float: right;*/
    background: white;
    padding: 25px;
    /*border: 1px solid #aaaaaa;*/
    margin-top: 20px;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    box-sizing: border-box;
}
#was, #infos, .input-full {
    width: calc(100vw - 325px) !important;
}

@media screen and (max-width:550px) {
    #summary {
        /*width: calc(100% - 23px);*/
        width: 100%;
        float: none;
    }
    #was, #infos, .input-full {
        width: calc(100% - 8px) !important;
    }
}

@media screen and (min-width:1000px) {
    #was, #infos, .input-full {
        width: 690px !important;
    }
}
#infos {
    height: 100px;
}

.shipment-flex {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    /*max-width: 400px;*/
}

.shipment-flex .input-container {
    display: flex;
    align-items: center;
}

.shipment-flex .input-container input {
    margin-right: 10px;
}

.shipment-nextrow {
    flex-basis: 100%;
}

#hintsOverlay {
    background: rgba(0, 0, 0, 0.2);
    color: #000000;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5001;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    display: none;
}
#hintsContainer {
    height: 80vh;
    width: 80vw;
    max-width: 500px;
    max-height: 220px;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    background: #ffffff;
    padding: 20px;
    border: 1px solid #aaaaaa;
    border-radius: 3px;
}
#hints_close {
    margin-top: -10px;
    cursor: pointer;
}

#hintsText {
    margin-bottom: 40px;
}

.cars-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0 15px;
    margin-bottom: 50px;
}
.car {
    display: flex;
    flex-direction: row;
    padding: 25px;
    max-width: 100%;
    flex: 0 100%;
    /*border: 1px solid #aaaaaa;*/
    border-radius: 15px;
    background: white;
    /*min-width: 270px;*/
    margin-bottom: 15px;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    box-sizing: border-box;
}

@media screen and (min-width: 600px) {
    .car {
        max-width: 48%;
        flex: 0 48%;
    }
}

.car input {
    margin-right: 7px;
}

#errorOverlay  {
    display: none;
}

.legal {
    margin-top: 25px;
}